<footer>
  <div class="container">
    <p class="text-muted">
      &copy; {{ today | date: 'yyyy' }}.
      Tournament pairings app by Steven Indzeoski.
      Version
      <a href="https://github.com/sten626/mirror-match/blob/master/CHANGELOG.md" target="_blank" rel="noopener noreferrer">
        {{ version }} <span class="glyphicon glyphicon-new-window" aria-hidden="true"></span>
      </a>.
      Code licensed under MIT.
    </p>
  </div>
</footer>
