<nav class="navbar navbar-default">
  <div class="container">
    <div class="navbar-header">
      <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#mm-navbar" aria-expanded="false">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <a class="navbar-brand" routerLink="/">Mirror Match</a>
    </div>

    <div id="mm-navbar" class="collapse navbar-collapse">
      <ul class="nav navbar-nav">
        <li routerLinkActive="active">
          <a routerLink="/swiss">Swiss</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
