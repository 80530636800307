import { NgModule } from '@angular/core';

import { HomeComponent } from './home.component';

@NgModule({
  declarations: [
    HomeComponent
  ]
})
export class HomeModule { }
